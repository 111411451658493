export const quickbloxConfig = {
    APPLICATION_ID: process.env.REACT_APP_QB_APPLICATION_ID || 91685,
    AUTH_KEY: process.env.REACT_APP_QB_AUTH_KEY||'wzvSLgQYrDxqJ6s',
    AUTH_SECRET: process.env.REACT_APP_QB_AUTH_SECRET||'3rwXkxGkwNj6WuX',
    ACCOUNT_KEY: process.env.REACT_APP_QB_ACCOUNT_KEY||'MnxcoqLKkj9xTpV8sJxC',
    CONFIG: {debug: true},

    LOGIN_USER: 'web_user',
    LOGIN_PASSWORD: 'quickblox',
};
