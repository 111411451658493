import React, {useEffect, useState} from "react";
import {HeaderFormStyled} from "../../components/Elements";
import styled from "styled-components";
import {ReactComponent as AddConsultIcon} from '../../assets/images/consultItem.svg';
import {ReactComponent as ConsultNewIcon} from '../../assets/images/consultNew.svg';
import {ReactComponent as ConsultFlupIcon} from '../../assets/images/consultFlup.svg';
import {ReactComponent as FlowUpIcon} from '../../assets/images/flup.svg';
import {ReactComponent as NewIcon} from '../../assets/images/flnew.svg';
import {useHistory, useParams} from "react-router-dom";
import {getConsultById, getConsultList, getConsultsByPatient} from "../../services/app.service";
import {AxiosResponse} from "axios";
import moment from "moment";
import Nav from "../../components/Nav";

const ContainerItem = styled.div`
  & {
    padding: 16px;
    width: calc(100% - 40px);
    background: #FFFFFF;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 35px;
    margin-left: 40px;
  }

  &:after {
    border-left: 1px solid lightgray;
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    top: -40px;
    left: 0;
  }
`;

const TextBold = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3A3B56;
  margin: 6px 0px;
  display: inline-block;
`;
const Text = styled.span`
  font-size: 16px;
  line-height: 18px;
  color: #6A6A6A;
  margin: 0px 0px;
  font-family: 'Bebeboo-Regular', sans-serif;
  display: inline-block;
`;
const TextRow = styled.div``;

const LeftActions = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  right: 16px;
`;
const BoxItemHeadTitle = styled.div`
  font-size: 16px;
  color: #4DBAAB;
`;
const Label = styled.span`
  font-size: 16px;
  line-height: 18px;
  color: #2A2F36;
  margin: 6px 0px;
  font-family: 'Bebeboo-SemiBold', sans-serif;
  display: block;
`;
const Title = styled.span`
  font-size: 18px;
  line-height: 18px;
  color: #4DBAAB;;
  margin: 8px 0px;
  display: block;
`;
const Icon = styled.div`
  position: absolute;
  top: 20px;
  left: -20px;
  z-index: 2;
`
const ButtonArchive = styled.div`
  height: 32px;
  background: #BDE5E0;
  border-radius: 10px;
  font-size: 14px;
  color: #4DBAAB;
  text-align: center;
  padding: 0 12px;
  line-height: 32px;
`

const body: any = document.querySelector("body");

export default function MapListScreen(props: any) {
    const history = useHistory();
    const [data, setData]: any = useState([]);
    const [page, setPage] = useState(0);
    let {consultId}: any = useParams();

    useEffect(() => {
        // getConsultById(consultId).then(value => {
        //     const patientId = value.data.patients[0]._id;
        getConsultsByPatient(consultId).then(value1 => {
            setData([...value1.data.data]);
        })
        // })
    }, [page]);

    const onDetail = (consultId: string, isFollowup: boolean = false) => {
        history.push('/agent/archive/detail/' + consultId + (isFollowup ? '?isFollowup=true' : ''))
    }

    const renderItem = (value: any, index: number) => {
        return (
            <ContainerItem className={index === 0 ? 'none-after' : ''} key={index.toString()}>
                <Icon>{value.type === 'NEW' ? <NewIcon/> : <FlowUpIcon/>}</Icon>
                <div>
                    <BoxItemHeadTitle>{moment(value.createdAt).format('DD MMM YYYY')}</BoxItemHeadTitle>
                    <Label>{value.type === 'NEW' ? 'Consult Created' : 'Followup Created'}</Label>
                    <Text>ID: {value._id}</Text>
                </div>
                <ButtonArchive onClick={() => onDetail(value._id, value.type !== 'NEW')}>View Archive</ButtonArchive>
            </ContainerItem>
        )
    }

    return (
        <div className="view-bg" style={{height: '100%'}}>
            <Nav isNavAgent={true} menu={false} title={`Consult Map - ${consultId}`}/>
            <div style={{backgroundColor: 'rgba(255,255,255,0.82)', borderRadius: 16, marginTop: 12}}>
                {
                    data.length > 0 ? data.map((value: any, index: number) => renderItem(value, index)) : (
                        <p>No data!</p>)
                }
            </div>
        </div>
    )
}
