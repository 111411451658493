import styled from "styled-components";
import {ReactComponent as ArrowDownIcon} from '../../assets/images/down.svg';

const Title = styled.h1`
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: #009599;
  text-align: center;
  margin-bottom: 5px;
  margin-top: 16px;
`;

const Button = styled.button`
  background: #4DBAAB;
  border-radius: 30px;
  height: 50px;
  border: none;
  width: 100%;
  font-style: normal;
  font-family: Bebeboo-SemiBold, 'Bebeboo-Regular', sans-serif;
  font-size: 20px;
  line-height: 23px;
  color: #FFFFFF;
  max-width: 260px;
  margin: 0 auto;
  cursor: pointer;
`;

const ButtonOutline = styled.button`
  background: #fff;
  border-radius: 30px;
  border: 1px solid #4DBAAB;
  height: 50px;
  width: 100%;
  font-style: normal;
  font-family: Bebeboo-SemiBold, 'Bebeboo-Regular', sans-serif;
  font-size: 20px;
  line-height: 23px;
  color: #4DBAAB;
  max-width: 260px;
  margin: 0 auto;
  cursor: pointer;
`;

const Label = styled.label`
  font-size: 16px;
  line-height: 20px;
  color: #8E8E8E;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 4px 0px;
  text-align: left;
  display: block;
  font-family: Bebeboo-Regular, sans-serif;
`;

const Input = styled.input`
  width: 100%;
  height: 48px;
  border: 1px solid #CCD3D3;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 12px 16px;
  text-align: left;
  font-family: Bebeboo-Regular, sans-serif;
  font-size: 18px;
`;

const Select = styled.select`
  width: 100%;
  height: 48px;
  border: 1px solid #CCD3D3;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 12px 16px;
  text-align: left;
  font-family: Bebeboo-Regular, sans-serif;
  font-size: 18px;
`;

const ControlInput = (props: any) => {
    return (
        <div id={props.id || ''} className={`${props.className} control`}>
            <Label>{props.label || ""}</Label>
            <Input onKeyPress={props.onKeyPress || null} defaultValue={props.defaultValue || ''}
                   value={props.value || ''}
                   onChange={props.onChange}
                   placeholder={props.placeholder || ""}/>
        </div>
    )
};

const ControlSelect = (props: any) => {
    return (
        <div id={props.id || ''} className={`${props.className} control`}>
            <Label>{props.label || ""}</Label>
            <Select defaultValue={props.defaultValue || ''} value={props.value || ''} onChange={props.onChange}
                    placeholder={props.placeholder || ""}>{props.children}</Select>
            <ArrowDownIcon style={{width: 15, position: "absolute", bottom: 20, right: 10}}/>
        </div>
    )
};

const ControlInputStyled = styled(ControlInput)`
  margin: 16px 0;
  width: 100%;
`;
const ControlSelectStyled = styled(ControlSelect)`
  margin: 16px 0;
  width: 100%;
  position: relative;
`;

const Link = styled.a`
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #009599;
  margin-bottom: 5px;
  cursor: pointer;
`;
const Text = styled.p`
  font-family: Bebeboo-Regular, sans-serif;
  font-size: 18px;
  line-height: 20px;
  color: #8E8E8E;
  margin: 0;
  margin-bottom: 5px;
`;

const BigText = styled.h4`
  font-size: 22px;
  line-height: 25px;
  color: #3A3B56;
  margin: 0;
`;

const ActionButton = styled.button`
  height: 40px;
  background: #EAF3F3;
  border-radius: 16px;
  font-size: 20px;
  line-height: 23px;
  color: #8E8E8E;
  border: none;
  padding: 0 20px;
`;

const HeaderForm = (props: any) => {
    return <div className={`${props.className} header-1`}>
        <BigText>{props.title || ""}</BigText>
        {props.actionText ? <ActionButton {...(props.propsAction || {})}>{props.actionText}</ActionButton> : null}
    </div>
}

const HeaderFormStyled = styled(HeaderForm)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const TwoCol = (props: any) => {
    return <div className={`${props.className} two-col`}>
        {props.children}
    </div>
}

const TwoColStyled = styled(TwoCol)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CenterStyled = styled((props: any) => {
        return <div className={`${props.className}`}>
            {props.children}
        </div>
    }
)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export
{
    Title,
    Button,
    Label,
    Input,
    ControlInput,
    ControlInputStyled,
    Link,
    Text,
    HeaderFormStyled,
    ControlSelectStyled,
    TwoColStyled,
    CenterStyled,
    ButtonOutline
}
