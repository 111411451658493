import React, {useEffect, useState} from 'react';
import {ReactComponent as Logo} from '../../assets/images/logo.svg';
import {ReactComponent as TopBackground} from '../../assets/images/topBg.svg';
import {Button, ControlInputStyled, Link, Title, Text, Label} from "../../components/Elements";
import PhoneControlStyled from "../../components/PhoneInput";
import {useHistory, useParams, useRouteMatch} from "react-router-dom";


export default function LogoutScreen(props: any) {
    const history = useHistory();
    const {type}: any = useParams();

    useEffect(() => {
        localStorage.clear();
        history.push(`/${type}/auth`);
    }, [])

    return null;
}

