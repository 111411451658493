import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect, useLocation
} from "react-router-dom";
import AuthScreen from "../screens/auth/index";
import ConsultScreen from "../screens/consult";
import ArchiveScreen from "../screens/archive";
import CallScreen from "../screens/patient/call.screen";
import CallingScreen from "../screens/patient/calling.screen";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import QuickbloxService from "../services/quickblox.service";
import {actionUserQBIdChange} from "../redux/actions/auth.action";
import ArchiveListScreen from "../screens/archive/list.screen";
import FollowupScreen from "../screens/archive/followup.screen";
import RegisterScreen from "../screens/auth/register.screen";
import LoginScreen from "../screens/auth/login.screen";
import OtpScreen from "../screens/auth/otp.screen";
import ConsultAddScreen from "../screens/consult/add.screen";
import ConsultChatScreen from "../screens/consult/chat.screen";
import HomeScreen from "../screens/home";
import LogoutScreen from "../screens/auth/logout.screen";
import OnGoingListScreen from "../screens/archive/ongoing.screen";
import {setPatientConsultId} from "../services/app.service";
import ArchiveDetailScreen from "../screens/archive/detail.screen";
import MapListScreen from "../screens/archive/map.screen";
import {
    TransitionGroup,
    CSSTransition
} from "react-transition-group";
import Loading from "../components/Loading";
import {lightTheme, MeetingProvider} from "amazon-chime-sdk-component-library-react";
import { ThemeProvider } from "styled-components";

export default function AppRoute(props: any) {
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.Auth.user);
    // let location = useLocation();

    useEffect(() => {
        if (!!user) {
            QuickbloxService.login(user.qbLogin, user.qbPassword).then((value: any) => {
                dispatch(actionUserQBIdChange(value.user_id))
            });
        }
    }, [user]);

    // Get current patient consult Id
    useEffect(() => {
        // const url = window.location.href;
        // const searchUrl = new URL(url);
        // const consultId: string = searchUrl.searchParams.get('consultId') || "";
        // setPatientConsultId(consultId);
    }, []);

    return <Router basename={''}>
        <Loading/>
        <Routers/>
    </Router>
}

function Routers(props: any) {
    const isPatient = process.env.REACT_APP_DEFAULT_TYPE === 'patient';
    return isPatient ? (
        <Switch>
            <Route path={`/:type/auth/login/:consultId`}>
                <AuthScreen>
                    <LoginScreen/>
                </AuthScreen>
            </Route>
            <Route path={`/:type/logout`}>
                <LogoutScreen/>
            </Route>
            <Route path={`/:type/auth/otp/:phone`}>
                <AuthScreen>
                    <OtpScreen/>
                </AuthScreen>
            </Route>

            <Route path={`/:type/consult/chat/:consultId`}>
                <ConsultScreen>
                    <ConsultChatScreen/>
                </ConsultScreen>
            </Route>

            <Route path={`/:type/consult/call/:consultId`}>
                <ConsultScreen>
                    <ThemeProvider theme={lightTheme}>
                        <MeetingProvider>
                            <CallScreen/>
                        </MeetingProvider>
                    </ThemeProvider>
                </ConsultScreen>
            </Route>

        </Switch>
    ) : (
        <Switch>


            <Route path={`/login/:bizCode`}>
                <AuthScreen>
                    <OtpScreen/>
                </AuthScreen>
            </Route>

            {/* Auth */}
            <Route path={`/:type/auth/register`}>
                <Redirect to="/agent/auth/login"/>
            </Route>
            <Route path={`/:type/auth/login`}>
                <AuthScreen>
                    <LoginScreen/>
                </AuthScreen>
            </Route>
            <Route path={`/:type/logout`}>
                <LogoutScreen/>
            </Route>
            <Route path={`/:type/auth/otp/:phone`}>
                <AuthScreen>
                    <OtpScreen/>
                </AuthScreen>
            </Route>

            <Route path={`/:type/login/:bizCode/:phone/:password`}>
                <AuthScreen>
                    <OtpScreen/>
                </AuthScreen>
            </Route>
            <Route path={`/:type/login/:bizCode/:phone`}>
                <AuthScreen>
                    <OtpScreen/>
                </AuthScreen>
            </Route>

            {/* Call */}
            <Route path="/:type/call">
                <CallScreen/>
            </Route>
            <Route path="/:type/calling">
                <CallingScreen/>
            </Route>
            <Route path="/:type/home">
                <HomeScreen/>
            </Route>

            {/* Archive */}
            <Route path={`/:type/archive/list`}>
                <ArchiveScreen>
                    <ArchiveListScreen/>
                </ArchiveScreen>
            </Route>
            <Route path={`/:type/archive/ongoing`}>
                <ArchiveScreen>
                    <OnGoingListScreen/>
                </ArchiveScreen>
            </Route>
            <Route path={`/:type/archive/followup/:consultId`}>
                <ArchiveScreen>
                    <FollowupScreen/>
                </ArchiveScreen>
            </Route>
            <Route path={`/:type/archive/detail/:consultId`}>
                <ArchiveScreen>
                    <ArchiveDetailScreen/>
                </ArchiveScreen>
            </Route>

            <Route path={`/:type/archive/map/:consultId`}>
                <ArchiveScreen>
                    <MapListScreen/>
                </ArchiveScreen>
            </Route>

            {/* Consult */}
            <Route path={`/:type/consult/add`}>
                <ConsultScreen>
                    <ConsultAddScreen/>
                </ConsultScreen>
            </Route>
            <Route path={`/:type/consult/chat/:consultId`}>
                <ConsultScreen>
                    <ConsultChatScreen/>
                </ConsultScreen>
            </Route>
            <Route path={`/:type/consult/call/:consultId`}>
                <ConsultScreen>
                    <ThemeProvider theme={lightTheme}>
                        <MeetingProvider>
                            <CallScreen/>
                        </MeetingProvider>
                    </ThemeProvider>
                </ConsultScreen>
            </Route>

            <Route path="/agent">
                <Redirect to="/agent/auth/login"/>
            </Route>
            <Route path="/agent/auth">
                <Redirect to="/agent/auth/login"/>
            </Route>
            <Route path="/">
                <Redirect to="/agent/auth/login"/>
            </Route>
        </Switch>
    )
}
