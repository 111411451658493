import React from "react";
import styled from "styled-components";
import {ReactComponent as DeclineIcon} from '../../assets/images/decline.svg';
import {ReactComponent as AcceptIcon} from '../../assets/images/accept.svg';
import './style.scss';

const CallInfo = styled.div``;
const CallActions = styled.div`
  font-family: 'Bebeboo-Regular', sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 240px;
`;
const Container = styled.div`
  background: rgba(30, 30, 30, 0);
  //backdrop-filter: blur(100px);
  //border-radius: 0px;
  //min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  position: fixed;
  bottom: 90px;
  left: 0;
  z-index: 10;
`;
const Image = styled.img`
  border: 4px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 80px;
  width: 148px;
  height: 148px;
`;
const Name = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #FFFFFF;
  font-family: 'Bebeboo-Regular', sans-serif;
  text-align: center;
  width: 100%;
`;
const Status = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  font-family: 'Bebeboo-Regular', sans-serif;
  text-align: center;
  width: 100%;
`;
const Button = styled.div`
  width: 60%;
  max-width: 200px;
`;
const ButtonText = styled.p`
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  margin: 0;
  font-family: 'Bebeboo-Regular', sans-serif;
  background-color: #4dbaab;
  padding: 10px 40px;
  border-radius: 30px;
  width: 100%;
`;

const body: any = document.querySelector('body');

export default function CallingScreen(props: any) {
    const consult = props.consult;
    const callId = props.callId;

    const onDecline = () => {
        if (props.onDecline) {
            props.onDecline()
        }
    }

    const onAccept = () => {
        if (props.onAccept) {
            props.onAccept()
        }
    }
    const getUserName = (qbUId: string) => {
        const arrayUsers = [...consult.doctors, ...consult.patients, ...consult.agents];
        const findItem = arrayUsers.find((value, index) => value.qbId.toString() === (qbUId?.toString() || ""));

        return findItem && findItem.fullname ? findItem.fullname : qbUId;
    }

    return (
        <Container>
            <Button onClick={event => onAccept()}>
                {/*<AcceptIcon/>*/}
                <ButtonText>Join Call</ButtonText>
            </Button>


            {/*<CallInfo>*/}
            {/*    <Image src='https://image.freepik.com/free-vector/doctor-character-background_1270-84.jpg'/>*/}
            {/*    <Name>{getUserName(callId)}</Name>*/}
            {/*    <Status>Call Video....</Status>*/}
            {/*</CallInfo>*/}
            {/*<CallActions>*/}
            {/*    <Button onClick={event => onDecline()}>*/}
            {/*        <DeclineIcon/>*/}
            {/*        <ButtonText>Decline</ButtonText>*/}
            {/*    </Button>*/}
            {/*    <Button onClick={event => onAccept()}>*/}
            {/*        <AcceptIcon/>*/}
            {/*        <ButtonText>Accept</ButtonText>*/}
            {/*    </Button>*/}
            {/*</CallActions>*/}
        </Container>
    )
}
