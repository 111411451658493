import {SET_CURRENT_CONSULT} from "../types/consult.type";

const defaultState = {
    current: null,
};

export default function (state: any = defaultState, action: any) {
    switch (action.type) {
        case SET_CURRENT_CONSULT: {
            return {
                ...state,
                current: action.payload.consult,
            };
        }

        default: {
            return state;
        }
    }
};
