import React, {useEffect} from 'react';
import './style.scss';
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

export default function ConsultScreen(props: any) {
    return (
        <div className="app-consult">
            {props.children}
        </div>
    );
}
