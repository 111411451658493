import {quickbloxConfig} from "../constants/quickblox";

const QB = require('quickblox/quickblox.min');
export default class QuickbloxService {
    static getQB() {
        return QB;
    }

    static connectChat(userCredentials: any = {jid: 0, password: ""}) {
        const {APPLICATION_ID} = quickbloxConfig;
        console.log(userCredentials);
        userCredentials.jid = QB.chat.helpers.getUserJid(userCredentials.jid, APPLICATION_ID)
        return new Promise((resolve, reject) => {
            QB.chat.connect(userCredentials, (error: any, contactList: any) => {
                if (error) {
                    reject(error);
                } else {
                    resolve(contactList)
                }
            });
        });
    }

    // static registerMessageListener(listener: any) {
    //     QB.chat.onMessageListener = listener;
    // }

    // static sendChatMessage(message: any, opponentId: number) {
    //     return QB.chat.send(opponentId, message);
    // }

    static async init() {
        const {
            APPLICATION_ID,
            AUTH_KEY,
            AUTH_SECRET,
            ACCOUNT_KEY,
            CONFIG,
        } = quickbloxConfig;
        QB.init(APPLICATION_ID, AUTH_KEY, AUTH_SECRET, ACCOUNT_KEY, CONFIG);
        //
        // Login
        // await QuickbloxService.login("+84949201900","teleconsult");
        //
        // // Connect chat
        // await QuickbloxService.connectChat({
        //   jid: 127867679,
        //   password: "teleconsult",
        // });
        //
        // // Join dialog
        // await QuickbloxService.joinDialog("6095002ace5b15001ecf3bea");
        // console.log("JOINED DIALOG", "6095002ace5b15001ecf3bea")
        //
        // // List message
        // const res = await QuickbloxService.listDialogMessage("6095002ace5b15001ecf3bea");
        // console.log(res)
        //
        // // Send message
        // const messageRes = await QuickbloxService.sendMessage("6095002ace5b15001ecf3bea", "Test message");
        // console.log("SEND MESSAGE SUCCESS", messageRes)
        //
        // // Listen new message
        // QuickbloxService.subscribeNewMessage(
        //   "6095002ace5b15001ecf3bea",
        //   console.log
        // );


    }

    static login(login: string, password: string) {
        return new Promise((resolve, reject) => {
            QB.createSession({login, password}, (error: any, result: any) => {
                if (!error) {
                    resolve(result);
                } else {
                    reject(error);
                }
            });
        })
    }

    static joinDialog(dialogId: string) {
        return new Promise((rs, rj) => {
            const dialogJid = QB.chat.helpers.getRoomJidFromDialogId(dialogId);
            try {
                QB.chat.muc.join(dialogJid, function (err: any, res: any) {
                    if (err) {
                        rj(err)
                    } else {
                        rs(res)
                    }
                });
            } catch (error) {
                rj(error)
            }
        })
    }


    static listDialogMessage(dialogId: string) {
        return new Promise((rs, rj) => {
            QB.chat.message.list(
                {
                    chat_dialog_id: dialogId,
                    sort_desc: "date_sent",
                    limit: 100,
                    skip: 0,
                },
                function (err: any, messages: any[]) {
                    if (err) {
                        rj(err)
                    } else {
                        rs(messages)
                    }
                }
            );
        })
    }

    static sendMessage(dialogId: string, body: string, params: any = {}) {
        return new Promise((rs, rj) => {
            const message: any = {
                type: "groupchat",
                body: body,
                extension: {
                    save_to_history: 1,
                    dialog_id: dialogId,
                    customParam1: "book",
                    customParam2: "21",
                    ...params
                },
                markable: 1,
            };

            const dialogJid = QB.chat.helpers.getRoomJidFromDialogId(dialogId);
            try {
                message.id = QB.chat.send(dialogJid, message);
                rs(message)
            } catch (e) {
                if (e.name === "ChatNotConnectedError") {
                    // not connected to chat
                }
                rj(e)
            }
        })
    }

    static subscribeNewMessage(dialogId: string, cb: (msg: any) => void) {
        QB.chat.onMessageListener = function (userId: string, msg: any) {
            let doctorId = null;
            try {
                const doctorSender = JSON.parse(msg.extension.new_occupants_ids);
                if (doctorSender && doctorSender.length > 0) {
                    doctorId = doctorSender[0];
                }


            } catch (e) {
                console.log(e);
            }
            console.warn('PPPPPPPPP',doctorId);
            if (msg.dialog_id === dialogId) {
                const formatedMsg = {
                    ...msg,
                    _id: msg.id,
                    all_read: false,
                    attachments: [],
                    chat_dialog_id: msg.dialog_id,
                    created_at: +msg.extension.date_sent * 1000 || Date.now(),
                    date_sent: +msg.extension.date_sent * 1000 || Date.now(),
                    delivered_ids: [],
                    message: msg.body,
                    read_ids: [],
                    recipient_id: 0,
                    sender_id: doctorId ? userId : userId,
                    updated_at: +msg.extension.date_sent * 1000 || Date.now(),
                    read: 0,
                    typeMessage: msg.extension.notification_type,
                    doctorId: doctorId
                };
                cb(formatedMsg)
            }
        };
    };

    static createCallSession(calleesIds: Array<any>, sessionType: any, additionalOptions: any = {}) {
        const session = QB.webrtc.createNewSession(calleesIds, QB.webrtc.CallType[sessionType], null, additionalOptions);
        return session;
    }

    static getListUsers(dialogJid: string) {
        return new Promise((resolve, reject) => {
            try {
                QB.chat.muc.listOnlineUsers(QB.chat.helpers.getRoomJidFromDialogId(dialogJid), (users: any) => {
                    resolve(users);
                });
            } catch (e) {
                reject(e);
                if (e.name === 'ChatNotConnectedError') {
                    // not connected to chat
                }
            }
        })
    }

    static onCallListener(cb: any) {
        if (!QB.webrtc) {
            return;
        }
        QB.webrtc.onCallListener = cb;
    }

    static onAcceptCallListener(cb: any) {
        if (!QB.webrtc) {
            return;
        }
        QB.webrtc.onAcceptCallListener = cb;
    }

    static onRemoteStreamListener(cb: any) {
        if (!QB.webrtc) {
            return;
        }
        QB.webrtc.onRemoteStreamListener = cb;
    }

    static onStopCallListener(cb: any) {
        if (!QB.webrtc) {
            return;
        }
        QB.webrtc.onStopCallListener = cb;
    }

    static onSessionConnectionStateChangedListener(cb: any) {
        if (!QB.webrtc) {
            return;
        }
        QB.webrtc.onSessionConnectionStateChangedListener = cb;
    }

    static PeerConnectionState() {
        if (!QB.webrtc) {
            return;
        }
        return QB.webrtc.PeerConnectionState
    }

    static getMediaDevices() {
        if (!QB.webrtc) {
            return;
        }
        return QB.webrtc.getMediaDevices();
    }

    static getMediaVideoDevices() {
        if (!QB.webrtc) {
            return;
        }
        return QB.webrtc.getMediaDevices('videoinput');
    }
}
// QuickbloxService.init();
