import React from 'react';
import './style.scss';

export default function AuthScreen(props: any) {
    return (
        <div className="app-auth">
            {props.children}
        </div>
    );
}

