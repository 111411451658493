import React from 'react';
import './App.css';
import {Provider} from "react-redux";
import storeRedux from "./redux/store.redux";
import AppRoute from "./routes";
import QuickbloxService from './services/quickblox.service';
import Loading from "./components/Loading";
QuickbloxService.init()

function App() {
    return (
        <div className="app">
            <Provider store={storeRedux}>
                <AppRoute/>
            </Provider>
        </div>
    );
}

export default App;
