import axios from "axios";
import store from "../redux/store.redux";

let TOKEN = "";
let SESSION_USER_ID = localStorage.getItem('SESSION_USER_ID') || "";
let PATIENT_CONSULT_ID = "";

const getToken = () => {
    console.log('xxx');
    return TOKEN;
}

const intent = axios.create({
    baseURL: (process.env.REACT_APP_API_ENDPOINT || 'https://dev.agent.da-care.co/api/v1'),
});

export function setToken(token: string) {
    TOKEN = token;
}

export function setPatientConsultId(ConsultId: string) {
    PATIENT_CONSULT_ID = ConsultId;
    console.log("PATIENT_CONSULT_ID", PATIENT_CONSULT_ID);
}

export function getPatientConsultId() {
    return PATIENT_CONSULT_ID;
}

export function setSessionUserId(userId: string) {
    SESSION_USER_ID = userId;
    localStorage.setItem('SESSION_USER_ID', userId);
}

export function getSessionUserId() {
    return SESSION_USER_ID;
}

export function signUp(username: string, fullname: string, type: string) {
    return intent.post('/auth/sign-up', {username, type, fullname}, {
        headers: {
            authorization: getToken()
        }
    });
}

export function getConsults() {
    return intent.get('/consults', {
        headers: {
            authorization: getToken()
        }
    });
}

export function postFollowup(consult: any, consultId: string) {
    return intent.post(`/consults`, {...consult, parentConsultsId: consultId}, {
        headers: {
            authorization: getToken()
        }
    });
}

export function signIn(username: string, password: string, type: string, consultId: string = '') {
    return intent.post('/auth/login', {username, type, password, consultId}, {
        headers: {
            authorization: getToken()
        }
    });
}

export function signInById(phoneNumber: string, agentId: string, bizCode: string, password: string = '') {
    return intent.post(`/auth/${bizCode}/login`, {phoneNumber, agentId}, {
        headers: {
            authorization: getToken()
        }
    });
}

export function leaveCall(consultId: string) {
    return intent.post(`/chime/leave`, {consultId, deviceId: ''}, {
        headers: {
            authorization: getToken()
        }
    });
}

export function joinCall(consultId: string) {
    return intent.post(`/chime/join`, {consultId, deviceId: ''}, {
        headers: {
            authorization: getToken()
        }
    });
}

export function checkPhone(phoneNumber: string, consultId: any = null) {
    return intent.post(`/auth/check-mobile`, {
        mobile: phoneNumber,
        ...(consultId ? {consult_id: consultId} : {}),
        type: (process.env.REACT_APP_DEFAULT_TYPE || 'agent').toString().toUpperCase()
    }, {
        headers: {
            authorization: getToken()
        }
    });
}


export function createConsult(consult?: any) {
    if (!consult.gender) {
        delete consult.gender;
    }
    if (!consult.age) {
        delete consult.age;
    }
    return intent.post('/consults', consult, {
        headers: {
            authorization: getToken()
        }
    });
}

export function getConsultById(consultId: string) {
    return intent.get(`/consults/${consultId}`, {
        headers: {
            authorization: getToken()
        }
    });
}

export function getConsultCaseNote(consultId: string) {
    return intent.get(`/consults/${consultId}/case-notes`, {
        headers: {
            authorization: getToken()
        }
    });
}

export function getConsultCaseNotePastVisit(consultId: string) {
    return intent.get(`/consults/${consultId}/case-notes/past-visit`, {
        headers: {
            authorization: getToken()
        }
    });
}

export function getConsultsByPatient(consultId: string) {
    return intent.get(`/consults/${consultId}/consult-map?start=0&end=100`, {
        headers: {
            authorization: getToken()
        }
    });
}

export function getConsultList(page: number = 0, status: any = undefined) {
    return intent.get(`/consults`, {
        headers: {
            authorization: getToken()
        },
        params: {start: page, status}
    });
}
