import * as AWS from 'aws-sdk/global';
import {v4} from 'uuid';

const appInstanceUserArnHeader = 'x-amz-chime-bearer';


async function sendChannelMessage(message: string, config: any = {memberArn: '', chatToken: '', channelArn: ''}) {
    console.log('sendChannelMessage called', config, message);
    // @ts-ignore
    const chime = new AWS.Chime({
        region: 'us-east-1',
        accessKeyId: config.chatToken.clientId,
        secretAccessKey: config.chatToken.clientKey
    });
    const params = {
        ChannelArn: config.channelArn,
        Content: message || '',
        Persistence: 'PERSISTENT', // Allowed types are PERSISTENT and NON_PERSISTENT
        Type: 'STANDARD', // Allowed types are STANDARD and CONTROL,
        ClientRequestToken: v4()
    };

    const request = chime.sendChannelMessage(params);
    request.on('build', function () {
        request.httpRequest.headers[appInstanceUserArnHeader] = config.memberArn;
    });
    return await request.promise();
}

async function listChannelMessages(config: any = {memberArn: '', chatToken: '', channelArn: ''}, nextToken = null) {
    console.log('listChannelMessages called');
    // @ts-ignore
    const chime = new AWS.Chime({
        region: 'us-east-1',
        accessKeyId: config.chatToken.clientId,
        secretAccessKey: config.chatToken.clientKey
    });
    const params = {
        ChannelArn: config.channelArn,
        NextToken: nextToken
    };

    const request = chime.listChannelMessages(params);
    request.on('build', function () {
        request.httpRequest.headers[appInstanceUserArnHeader] = config.memberArn;
    });
    const response = await request.promise();
    const messageList = response.ChannelMessages;
    messageList.sort(function (a: any, b: any) {
        // eslint-disable-next-line no-nested-ternary
        return a.CreatedTimestamp < b.CreatedTimestamp ? -1 : a.CreatedTimestamp > b.CreatedTimestamp ? 1 : 0;
    });

    const messages = [];
    for (let i = 0; i < messageList.length; i++) {
        const message = messageList[i];
        messages.push(message);
    }
    return {Messages: messages, NextToken: response.NextToken};
}

export {
    sendChannelMessage,
    listChannelMessages,
};
