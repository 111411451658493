import React, {useEffect, useState} from 'react';
import {ReactComponent as Logo} from '../../assets/images/logo.svg';
import {ReactComponent as TopBackground} from '../../assets/images/topBg.svg';
import {Button, ControlInputStyled, Link, Title, Text, Label} from "../../components/Elements";
import OtpInput from 'react-otp-input';
import {useHistory, useLocation, useParams, useRouteMatch} from "react-router-dom";
import {
    getConsultList,
    getPatientConsultId,
    setSessionUserId,
    setToken,
    signIn,
    signInById
} from "../../services/app.service";
import {useDispatch} from "react-redux";
import {actionAuthChange, actionUserQBIdChange} from "../../redux/actions/auth.action";
import QuickbloxService from "../../services/quickblox.service";
import moment from "moment";
import {setLoading} from "../../redux/actions/app.action";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function OtpScreen(props: any) {
    const [value, setValue] = useState('');
    const [agentId, setAgentId] = useState('');
    const [phoneCache, setPhoneCache] = useState('');
    const [typeCache, setTypeCache] = useState('');
    const history = useHistory();
    const {phone, type, bizCode, password}: any = useParams();
    const dispatch = useDispatch();
    let query = useQuery();

    useEffect(() => {
        if (query.get('agentId') && query.get('mobileno')) {
            onSubmitById((query.get('mobileno') || ''), (query.get('agentId') || ''), bizCode).then();
            return;
        }
        if (password) {
            setValue(atob(password));
            onSubmit(atob(password)).then();
        }
    }, []);

    const onResent = () => {

    }

    const onSubmit = async (pass: string) => {
        let p = phone;
        let t = type;
        if (phoneCache) {
            history.push(`/${typeCache}/home`);
            return
        }
        if (!pass || pass.length < 6) {
            alert('Otp is a required field');
            return;
        }
        dispatch(setLoading(true));
        const result = await signIn(p, pass, t.toString().toUpperCase(), getPatientConsultId()).catch(err => {
            if (err.response.status === 404) {
                alert('Verification failed. Please try again');
                return;
            }
            if (err.response.data && err.response.data.message) {
                alert('Verification failed. Please try again' || err.response.data.message);
            } else {
                alert('Cannot login, please check and try again');
            }
            return null;
        });
        if (result) {
            setToken(result.data.token);
            dispatch(actionAuthChange(result.data));
            if (type === 'agent') {
                history.push(`/${type}/home`);
            } else {
                let consultId = getPatientConsultId();
                console.log(consultId);
                setTimeout(() => {
                    history.push(`/${type}/consult/chat/${consultId}`)
                }, 500)
            }
        }
        dispatch(setLoading(false));
    }

    const onSubmitById = async (phoneN: string, agentIdN: string, bizCodeN: string) => {
        const result = await signInById(phoneN, agentIdN, bizCodeN).catch(err => {
            history.push(`/agent/auth/login`);
            setTimeout(() => {
                if (err.response.data && err.response.data.message) {
                    alert('Verification failed. Please try again' || err.response.data.message);
                } else {
                    alert('Cannot login, please check and try again');
                }
            }, 500)
            return null;
        });
        if (result) {
            // console.log(result);
            setToken(result.data.token);
            dispatch(actionAuthChange({...result.data}));
            const resultQB: any = await QuickbloxService.login(result.data.qbLogin, result.data.qbPassword).catch(e => null);
            if (!!resultQB && !!resultQB.user_id) {
                dispatch(actionUserQBIdChange(resultQB.user_id));
                setSessionUserId(resultQB.user_id);
                // history.push(`/agent/home`);
            } else {
                alert('Cannot login Quickblox, please check and try again');
            }
            setPhoneCache('+' + phoneN.trim());
            setTypeCache('agent');

        }
    }

    return (
        <div className="auth-register">
            <div className="bg-top">
                <TopBackground/>
            </div>
            <div className='logo'>
                <Logo/>
            </div>
            <Title>OTP Verification</Title>
            <Text className="center" style={{padding: '20px 10% 0 10%'}}>Enter the OTP you received to</Text>
            <Text className="center" style={{padding: '0px 10% 5px 10%'}}>{phone}</Text>
            <div className="controls">
                {// @ts-ignore
                }<OtpInput
                    shouldAutoFocus={true}
                    value={value}
                    onChange={(v: string) => {
                        setValue(v);
                    }}
                    numInputs={6}
                    isInputNum={true}
                    inputStyle={{
                        width: '48px',
                        height: '48px',
                        border: '1px solid #CCD3D3',
                        borderRadius: '16px',
                        fontSize: 17
                    }}
                    containerStyle={{
                        display: 'flex',
                        alineItems: 'center',
                        justifyContent: 'space-between'
                    }}
                />
            </div>
            <Link style={{padding: '0px 10% 15px 10%'}} onClick={() => onResent()}>Resend OTP</Link>

            <Button onClick={() => onSubmit(value)} className="button-register">Next</Button>
        </div>
    );
}

