import React, {ChangeEvent, useEffect, useState} from "react";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import styled from "styled-components";
import {Label} from "../Elements";
import en from 'react-phone-number-input/locale/en.json';
import {getCountries, getCountryCallingCode} from 'react-phone-number-input';
// @ts-ignore
import Input from "react-phone-number-input/input";
import "./style.scss";
import {parsePhoneNumber} from 'react-phone-number-input';

const CountrySelect = (props: any) => {
    const {value, onChange, labels, ...rest} = props;
    return <select
        {...rest}
        value={value}
        onChange={event => onChange(event.target.value || undefined)}>
        <option value="">
            {labels['ZZ']}
        </option>
        {getCountries().map((country) => (
            <option key={country} value={country}>
                {labels[country]} +{getCountryCallingCode(country)}
            </option>
        ))}
    </select>
}

const PhoneControl = (props: any) => {
    const [value, setValue]: any = useState('');
    const [country, setCountry] = useState<any>('SG')

    useEffect(() => {
        setValue('');
    }, [country]);

    useEffect(() => {
        if (props.onChange) {
            props.onChange('+' + getCountryCallingCode(country) + value)
        }
    }, [value]);

    return <div>
        <Label>Mobile no.</Label>
        <div className="PhoneInputCountryStyle">
            <div className="PhoneInputCountry">
                <CountrySelect
                    className='PhoneInputCountrySelect'
                    labels={en}
                    value={country}
                    onChange={setCountry}/>
                <div className="PhoneInputCountryIcon PhoneInputCountryIcon--border">
                    <img className="PhoneInputCountryIconImg" alt="American Samoa"
                         src={`https://purecatamphetamine.github.io/country-flag-icons/1x1/${country}.svg`}/>
                    <span className="PhoneInputCountryCode">+{getCountryCallingCode(country)}</span>
                </div>
            </div>
            <input
                name={'phone'}
                className='PhoneInputCountryInput'
                type={'phone'}
                value={value}
                defaultValue={value}
                onKeyPress={props.onKeyPress || null}
                onChange={(v: any) => {
                    const phone: any = parseInt(v.target.value.toString(), 10) || '';
                    setValue(phone.toString());
                }}/>
        </div>
    </div>;
}

const PhoneControlStyled = styled(PhoneControl)`

`;

export default PhoneControlStyled;
