import {combineReducers} from "redux";
import Auth from "./reducers/auth.reducer";
import Consult from "./reducers/consult.reducer";
import {LOADING_CHANGE} from "./types/app.type";

const defaultState = {
    loading: false,
}
const App = (state: any = defaultState, action: any) => {
    switch (action.type) {
        case LOADING_CHANGE: {
            return {
                ...state,
                loading: action.payload.loading
            }
        }
        default: {
            return state;
        }
    }
}

export default combineReducers({
    App, Auth, Consult
});
