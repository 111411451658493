import {v4 as uuid} from 'uuid';
import AWS from 'aws-sdk';
import * as Chime from 'aws-sdk/clients/chime';
import {
    LogLevel,
    ConsoleLogger,
    DefaultMessagingSession,
    MessagingSessionConfiguration
} from 'amazon-chime-sdk-js';


class ChimeMessagingService {
    private sessionId: string = uuid();
    private _session: any = null;
    private _logger = new ConsoleLogger('SDK Chat', LogLevel.INFO);
    private _messageUpdateCallbacks: any = [];

    constructor() {
    }

    messageObserver = {
        messagingSessionDidStart: () => {
            console.log('Messaging Connection started!');
        },
        messagingSessionDidStartConnecting: (reconnecting: any) => {
            console.log('Messaging Connection connecting');
        },
        messagingSessionDidStop: (event: any) => {
            console.log('Messaging Connection received DidStop event');
        },
        messagingSessionDidReceiveMessage: (message: any) => {
            console.log('Messaging Connection received message');
            this.publishMessageUpdate(message);
        }
    };

    async connect(userArn: string, chatToken: any) {
        // @ts-ignore
        const chime = new Chime({
            region: 'us-east-1',
            accessKeyId: chatToken.clientId,
            secretAccessKey: chatToken.clientKey
        });
        const endpoint: any = await chime.getMessagingSessionEndpoint().promise();
        const configuration = new MessagingSessionConfiguration(userArn, this.sessionId, endpoint.Endpoint.Url, chime, AWS);
        this._session = new DefaultMessagingSession(
            configuration,
            this._logger
        );
        this._session.addObserver(this.messageObserver);
        this._session.start();
    }

    close() {
        try {
            this._session.stop();
        } catch (err) {
            console.error('Failed to stop Messaging Session.');
        }
    }

    subscribeToMessageUpdate(callback: any) {
        console.log('Message listener subscribed!');
        this._messageUpdateCallbacks = [];
        this._messageUpdateCallbacks.push(callback);
    }

    unsubscribeFromMessageUpdate(callback: any) {
        const index = this._messageUpdateCallbacks.indexOf(callback);
        if (index !== -1) {
            this._messageUpdateCallbacks.splice(index, 1);
        }
    }

    publishMessageUpdate(message: any) {
        console.log(`Sending message update to listeners!`);
        for (let i = 0; i < this._messageUpdateCallbacks.length; i += 1) {
            const callback: any = this._messageUpdateCallbacks[i];
            callback(message);
        }
    }
}

export default ChimeMessagingService;
