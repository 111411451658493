import {applyMiddleware, createStore} from "redux";
import rootReducer from './reducer.redux';
import thunk from 'redux-thunk'
import {setToken} from "../services/app.service";

const middleware = [thunk];

const persistedState = localStorage.getItem('reduxState') ? JSON.parse(localStorage.getItem('reduxState') || "") : {};

if (persistedState && persistedState.Auth && persistedState.Auth.user && persistedState.Auth.user.token) {
    setToken(persistedState.Auth.user.token);
}

if (persistedState && persistedState.App) {
    persistedState.App.loading = false;
}


const store = createStore(rootReducer, persistedState, applyMiddleware(...middleware));

store.subscribe(() => {
    localStorage.setItem('reduxState', JSON.stringify(store.getState()));
});

export default store;
