import {AUTH_CHANGE, USER_QBID_CHANGE} from "../types/auth.type"
import jwt_decode from "jwt-decode";
import {setToken} from "../../services/app.service";

export const actionAuthChange = (user: any) => async (dispatch: any) => {
    if (user && user.token) {
        const decoded: any = jwt_decode(user.token);
        if (decoded && decoded.fullname) {
            user.fullname = decoded.fullname || decoded.qbId;
        }
        setToken(user.token);
    }

    dispatch({
        type: AUTH_CHANGE,
        payload: {
            user
        }
    });
}

export const actionUserQBIdChange = (userQBId: any) => async (dispatch: any) => {
    dispatch({
        type: USER_QBID_CHANGE,
        payload: {
            userQBId
        }
    });
}
