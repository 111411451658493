import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {ReactComponent as AddConsultIcon} from '../../assets/images/addConsult.svg';
import {ReactComponent as ArrowDownIcon} from '../../assets/images/down.svg';
import {useHistory} from "react-router-dom";
import {getConsultList} from "../../services/app.service";
import {AxiosResponse} from "axios";
import moment from "moment";
import Nav from "../../components/Nav";
import {useDispatch} from "react-redux";
import {setLoading} from "../../redux/actions/app.action";

const ContainerItem = styled.div`
  padding: 16px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 16px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px;
  position: relative;
  transition: 0.2s all;

  &:hover {
    background-color: #fafafa;
  }
`;

const TextBold = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3A3B56;
  margin: 6px 0px;
  display: inline-block;
`;
const Text = styled.span`
  font-size: 16px;
  line-height: 18px;
  color: #6A6A6A;
  margin: 0px 4px;
  font-family: 'Bebeboo-Regular', sans-serif;
  display: inline-block;
`;
const TextRow = styled.div``;

const LeftActions = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  right: 16px;
`;

const h = window.innerHeight - 76;

export default function ArchiveListScreen(props: any) {
    const history = useHistory();
    const [data, setData]: any = useState([]);
    const [page, setPage] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        getData(page).then();
        // body.addEventListener('scroll', handleScroll);
        // return () => {
        //     body.removeEventListener('scroll', handleScroll);
        // }
    }, [page]);

    const getData = async (page: number) => {
        dispatch(setLoading(true));

        getConsultList(page, 'CLOSED').then((value: AxiosResponse) => {
            // getConsultList(page * 10, null).then((value: AxiosResponse) => {
            if (value.status === 200) {
                if (page > 0) {
                    setData([...data, ...value.data.data])
                } else {
                    setData(value.data.data)
                }
            }
            dispatch(setLoading(false));

        });
    }

    const handleScroll = (e: any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            setPage(page + 1)
        }
    }

    const onDetail = (consultId: string) => {
        history.push('/agent/archive/detail/' + consultId)
    }

    const getUserName = (qbUId: string, consult: any) => {
        const arrayUsers = [...consult.doctors, ...consult.patients, ...consult.agents];
        const findItem = arrayUsers.find((value, index) => value._id.toString() === qbUId.toString());

        return findItem ? findItem.fullname : qbUId;
    }

    const getPatientOrDoctor = (consult: any) => {
        const arrayUsers = [...consult.patients]
        return arrayUsers.length > 0 ? arrayUsers[0] : null;
    }

    const openFollowup = (consultId: string) => {
        history.push('/agent/archive/followup/' + consultId)
    }

    const renderItem = (value: any, index: number) => {
        const user = getPatientOrDoctor(value);
        return (
            <ContainerItem key={index.toString()}>
                <div style={{cursor: "pointer"}} onClick={() => onDetail(value._id)}>
                    <TextRow>
                        <TextBold>ConsultID: {value._id}</TextBold>
                    </TextRow>
                    <TextRow>
                        <Text>Name</Text>
                        <TextBold>{user ? (user.fullname || "") : ""}</TextBold>
                    </TextRow>
                    <TextRow>
                        <Text>{moment(value.updatedAt).format("DD/MM/YYYY HH:mm:ss")}</Text>
                    </TextRow>
                    <TextRow>
                        <TextBold>{user ? (user.username || "") : ""}</TextBold>
                    </TextRow>
                </div>
                <LeftActions>
                    <AddConsultIcon style={{cursor: "pointer"}} onClick={() => openFollowup(value._id)}/>
                    <ArrowDownIcon style={{cursor: "pointer"}} onClick={() => onDetail(value._id)}/>
                </LeftActions>
            </ContainerItem>
        )
    }

    return (
        <div className="list-archive">
            <Nav isNavAgent={true} menu={false} title="Archive"/>
            <div style={{height: h, overflowY: "auto"}} onScroll={handleScroll}>
                {
                    data.length > 0 ? data.map((value: any, index: number) => renderItem(value, index)) : (
                        <p>No data!</p>)
                }
            </div>
        </div>
    )
}
