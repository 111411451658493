import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {ReactComponent as AddIcon} from '../../assets/images/add.svg';
import {ReactComponent as MuteIcon} from '../../assets/images/mute.svg';
import {ReactComponent as OnCameraIcon} from '../../assets/images/onCamera.svg';
import {ReactComponent as FrontCameraIcon} from '../../assets/images/frontCamera.svg';
import {ReactComponent as UnmuteIcon} from '../../assets/images/unmute.svg';
import {ReactComponent as CloseIcon} from '../../assets/images/close.svg';
import {ReactComponent as OffCameraIcon} from '../../assets/images/offCamera.svg';
import './style.scss';
import {
    RemoteVideo,
    AudioInputControl, AudioOutputControl,
    ContentShare,
    DeviceLabels,
    MeetingProvider,
    RemoteVideos,
    useContentShareControls,
    useMeetingManager,
    useToggleLocalMute,
    VideoInputControl,
    VideoTileGrid,
    PreviewVideo,
    useRemoteVideoTileState,
    useLocalVideo,
    useSelectVideoInputDevice, useRosterState, RosterAttendee, LocalVideo
} from 'amazon-chime-sdk-component-library-react';
import {joinCall, leaveCall} from "../../services/app.service";
import {useHistory, useParams} from "react-router-dom";
import {setLoading} from "../../redux/actions/app.action";
import {useDispatch} from "react-redux";
import {MeetingSessionStatus, MeetingSessionStatusCode} from "amazon-chime-sdk-js";

const Users = styled.div`
  display: flex;
  height: 115px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-right: 10px;
`;
const UserItem = styled.div`
  width: 93px;
  height: 111px;
  background: #5C5C5C;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin: 0 5px;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const UserItemName = styled.p`
  font-size: 16px;
  line-height: 18px;
  align-items: center;
  color: #FFFFFF;
  font-family: 'Bebeboo-Regular', sans-serif;
  position: absolute;
  bottom: 0;
  margin: 0;
  text-align: center;
  width: 100%;
  height: 20px;
  display: block;
`;
const UserItemImage = styled.video`
  width: 93px;
  height: 111px;

`;
const UserItemNone = styled.p`
  width: 93px;
  height: 111px;
  background: #000;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0 5px;
  flex: none;
`;

const UserItemNameNone = styled.p`
  font-size: 16px;
  line-height: 18px;
  align-items: center;
  color: #FFFFFF;
  font-family: 'Bebeboo-Regular', sans-serif;
  bottom: 0;
  margin: 0;
  text-align: center;
  width: 100%;
  display: block;
  padding: 10px;
`;
const Call = styled.div`
  flex: 1;
  width: 100%;
  position: relative;
`;
const CallName = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
  font-size: 20px;
  line-height: 30px;
  color: #FFFFFF;
  font-family: 'Bebeboo-Regular', sans-serif;
`;
const CallImage = styled.video`
  flex: 1;
  width: 100%;
  height: 100%;
`;
const CallImageContainer = styled.div`
  flex: 1;
  width: 100%;
  max-height: calc(100% - 180px);
  overflow: hidden;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContainerTop = styled.div`
  background: linear-gradient(180deg, #000000ed 3.33%, rgba(0, 0, 0, 0) 100%);
  height: 120px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
`;
const CallBottom = styled.div`
  background: rgba(30, 30, 30, 0.5);
  backdrop-filter: blur(100px);
  border-radius: 0px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const CallActions = styled.div`
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const CallActionItem = styled.div`
  text-align: center;
`;
const CallActionItemText = styled.p`
  font-size: 11px;
  line-height: 13px;
  display: block;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  margin: 0;
  width: 100%;
`;
const CallActionRow = styled.div`
  width: 308px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
`;
const CallActionRowItem = styled.div`
  height: 43px;
  background: rgba(255, 255, 255, 0.16);
  border-radius: 999px;
  width: 137px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CallActionRowItemText = styled.p`
  font-size: 11px;
  line-height: 13px;
  align-items: center;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  margin: 0;
  margin-left: 6px;
`;
const SpaceUser = styled.div`
  width: 2px;
  height: 5px;
  flex: none;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const body: any = document.querySelector('body');
let INITED = false;
export default function CallScreen(props: any) {
    let {consultId}: any = useParams();
    const [currentViewUser, setCurrentViewUser]: any = useState();
    const [currentUser, setCurrentUser] = useState();
    const meetingManager = useMeetingManager();
    const {muted, toggleMute} = useToggleLocalMute();
    const {tiles, attendeeIdToTileId} = useRemoteVideoTileState();
    const {tileId, isVideoEnabled, setIsVideoEnabled, toggleVideo} = useLocalVideo();
    const selectVideoInput = useSelectVideoInputDevice();
    const {roster} = useRosterState();
    const attendees = Object.values(roster);
    useEffect(() => {
        INITED = false;
    }, [])
    useEffect(() => {
        if (!isVideoEnabled && !INITED) {
            toggleVideo();
        }
    }, [isVideoEnabled, attendees]);
    const history = useHistory();
    const dispatch = useDispatch();


    const attendeeItems = attendees.map(attendee => {
        return {
            tileId: attendeeIdToTileId[attendee.chimeAttendeeId],
            name: attendee.externalUserId?.split('#')[1],
            chimeAttendeeId: attendee.chimeAttendeeId
        }
    });

    useEffect(() => {
        getMeetingDetail().then();
    }, []);

    useEffect(() => {
        console.log(MeetingSessionStatusCode);

    }, [])

    const getMeetingDetail = async () => {
        dispatch(setLoading(true));
        const result: any = await joinCall(consultId).catch(err => {
            alert('Cannot start call!!');
            onClose();
            return null;
        });
        if (result) {
            await initChime(result.data.JoinInfo);
        }
        dispatch(setLoading(false));
    }

    const initChime = async (meetingConfig: any) => {
        const joinData = {
            meetingInfo: meetingConfig.Meeting,
            attendeeInfo: {
                ...meetingConfig.Attendee,
            },
            deviceLabels: DeviceLabels.AudioAndVideo,
        };
        setCurrentUser(meetingConfig.Attendee.Attendee.AttendeeId);
        // Use the join API to create a meeting session
        await meetingManager.join(joinData);

        // At this point you can let users setup their devices, or start the session immediately
        await meetingManager.start();

        meetingManager.audioVideo?.addObserver({
            audioVideoDidStop: (sessionStatus: MeetingSessionStatus) => {
                onClose();
            }
        })
    }


    const getUserName = () => {
        if (attendeeItems && tiles && tiles[0]) {
            const findItem = attendeeItems.find(x => x.tileId === tiles[0]);
            if (findItem) {
                return findItem.name;
            }
        }
        return '';
    }


    const onClose = () => {
        leaveCall(consultId).then(async () => {
            await meetingManager.leave();
            history.push(`/agent/consult/chat/${consultId}`)
        });
    }

    return (
        <div className="popup-call">
            <Container>
                <div style={{width: body.clientWidth, overflowX: "auto", paddingRight: 10, maxWidth: 500}}>
                    <Users>

                        <UserItem key={-1}>
                            <LocalVideo/>
                            <UserItemName>You</UserItemName>
                        </UserItem>

                        {
                            attendeeItems.filter(x => x.chimeAttendeeId !== currentUser).map((value: any, index: number) => {
                                return (
                                    <UserItem key={index}>
                                        <RemoteVideo tileId={value.tileId}/>
                                        <UserItemName>{value.name}</UserItemName>
                                    </UserItem>
                                )
                            })
                        }
                        <UserItemNone style={{backgroundColor: '#5C5C5C'}}>
                            <AddIcon/>
                        </UserItemNone>
                        <SpaceUser/>
                    </Users>
                </div>
                <Call>
                    <ContainerTop/>
                    <CallName>{getUserName()}</CallName>
                    <CallImageContainer style={{height: (window.innerHeight - 180 - 115)}}>
                        {tiles.filter((item, index) => index === 0).map(tileId => {
                            return <RemoteVideo key={tileId} tileId={tileId}/>
                        })}
                    </CallImageContainer>
                    <CallBottom>
                        <CallActions>
                            <CallActionItem onClick={toggleMute}>
                                {muted ? <MuteIcon/> : <UnmuteIcon/>}
                                <CallActionItemText>{muted ? "mute" : "unmute"}</CallActionItemText>
                            </CallActionItem>
                            {/*<CallActionItem onClick={event => {*/}
                            {/*}}>*/}
                            {/*    <FrontCameraIcon/>*/}
                            {/*    <CallActionItemText>flip</CallActionItemText>*/}
                            {/*</CallActionItem>*/}
                            <CallActionItem onClick={event => onClose()}>
                                <CloseIcon/>
                                <CallActionItemText>end</CallActionItemText>
                            </CallActionItem>
                        </CallActions>
                        <CallActionRow>
                            <CallActionRowItem onClick={() => {
                                INITED = true;
                                toggleVideo();
                            }}>
                                {isVideoEnabled ? (<OffCameraIcon/>) : (<OnCameraIcon/>)}
                                <CallActionRowItemText>{isVideoEnabled ? "Camera Off" : "Camera On"}</CallActionRowItemText>
                            </CallActionRowItem>
                            {/*<CallActionRowItem>*/}
                            {/*    <SpeakerIcon/>*/}
                            {/*    <CallActionRowItemText>Speaker</CallActionRowItemText>*/}
                            {/*</CallActionRowItem>*/}
                        </CallActionRow>
                    </CallBottom>
                </Call>
            </Container>
        </div>
    )
}
