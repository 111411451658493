import React, {useState} from "react";
import styled from "styled-components";
import {ReactComponent as MenuIcon} from '../../assets/images/menu.svg';
import {ReactComponent as BackIcon} from '../../assets/images/back.svg';
import {ReactComponent as CallIcon} from '../../assets/images/call.svg';
import {ReactComponent as VideoIcon} from '../../assets/images/video.svg';
import {ReactComponent as AddUserIcon} from '../../assets/images/addUser.svg';
import {ReactComponent as SignOutIcon} from '../../assets/images/signout.svg';
import {ReactComponent as ShareIcon} from '../../assets/images/share.svg';
import {useHistory} from "react-router-dom";

const Container = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F1F4F6;
  margin: -16px;
  margin-bottom: 0;
  padding-left: 10px;
`;
const ContainerLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1
`;
const ContainerRight = styled.div`
  width: 42px;
  height: 40px;
  background: #FFFFFF;
  border-radius: 10px;
  margin: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
const Back = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  text-align: left;
  margin-left: -10px;
  margin-top: 10px;
  cursor: pointer;
`;
const Title = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #3A3B56;
  margin-left: 16px;
  font-family: Bebeboo, Bebeboo-Regular, sans-serif;
`;

const Menu = styled.div`position: absolute;
  top: 45px;
  z-index: 9;
  width: 60px;
  background: #EAF3F3;
  border-radius: 10px;
  padding: 10px;`;
const MenuItem = styled.div`
  width: 40px;
  height: 40px;
  background: #FFFFFF;
  border-radius: 20px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 11px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function Nav(props: any) {
    const history = useHistory();
    const [menuVisible, setMenuVisible] = useState(false);

    const onEvent = (evtName: string) => {
        if (props.onEvent) {
            props.onEvent(evtName);
        }
        setMenuVisible(false);
    }

    const renderMenu = () => {
        return (
            <Menu>
                {/*<MenuItem><CallIcon onClick={() => onEvent('AUDIO_CALL')}/></MenuItem>*/}
                {/*<MenuItem><VideoIcon onClick={() => onEvent('VIDEO_CALL')}/></MenuItem>*/}
                {props.isNavAgent ? (
                    <>
                        <MenuItem><AddUserIcon onClick={() => onEvent('ADD_USER')}/></MenuItem>
                        <MenuItem><ShareIcon onClick={() => onEvent('SHARE')}/></MenuItem>
                        <MenuItem><SignOutIcon onClick={() => onEvent('EXIT')}/></MenuItem>
                    </>
                ) : (
                    <MenuItem><SignOutIcon onClick={() => onEvent('SIGN_OUT')}/></MenuItem>
                )}

            </Menu>
        )
    }

    const onBack = () => {
        if (!!props.backTo) {
            history.push(props.backTo);
        } else {
            history.goBack();
        }
    }

    return <Container style={{backgroundColor: '#F1F4F6'}}>
        <ContainerLeft>
            {
                props.isNavAgent ? <Back onClick={() => onBack()}>
                    <BackIcon/>
                </Back> : null
            }
            <Title style={{marginLeft: (props.isNavAgent ? 16 : 0)}}>{props.title}</Title>
        </ContainerLeft>
        {
            props.menu ? (
                <ContainerRight tabIndex={10} onBlur={() => setMenuVisible(false)}>
                    <MenuIcon onClick={() => setMenuVisible(!menuVisible)}/>
                    {menuVisible ? renderMenu() : null}
                </ContainerRight>
            ) : (<div/>)
        }
    </Container>
}
