import React, {useEffect, useState} from "react";
import Nav from "../../components/Nav";
import styled from "styled-components";
import {Button, CenterStyled, Input} from "../../components/Elements";
import {getConsultById, postFollowup} from "../../services/app.service";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setLoading} from "../../redux/actions/app.action";

const ContainerItem = styled.div`
  padding: 16px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 16px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px;
  position: relative;
`;

const TextBold = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3A3B56;
  margin: 6px 0px;
  display: inline-block;
`;
const Text = styled.span`
  font-size: 16px;
  line-height: 18px;
  color: #3A3B56;
  margin: 8px 0px;
  display: block;
  font-family: 'Bebeboo-Regular', sans-serif;
`;
const Label = styled.span`
  font-size: 16px;
  line-height: 18px;
  color: #2A2F36;
  margin: 6px 0px;
  font-family: 'Bebeboo-SemiBold', sans-serif;
  display: block;
`;
const Title = styled.span`
  font-size: 16px;
  line-height: 18px;
  color: #4DBAAB;;
  margin: 8px 0px;
  display: block;
`;

const TextRow = styled.div`
  margin: 15px 0;
`;

const TitleBlock = styled.span`
  font-size: 22px;
  line-height: 24px;
  color: #000;;
  margin: 4px 0px;
  display: block;
`;

const PinButton = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 70px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PinButtonContent = styled.div`max-width: 500px;
  display: flex;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  flex: 1;
  height: 70px;
  padding: 10px;
`;

const Container = styled.div`
  position: relative;
  padding-bottom: 80px;
`;

export default function FollowupScreen(props: any) {
    const history = useHistory();
    const dispatch = useDispatch();
    let {consultId}: any = useParams();
    const [consult, setConsult]: any = useState();
    const [reason, setReason]: any = useState('');
    const [load, setLoad]: any = useState(false);

    useEffect(() => {
        const run = async () => {
            const result: any = await getConsultById(consultId).catch(reason => null);
            if (result.status === 200) {
                setConsult(result.data);
            }
        }
        run().then();
    }, []);

    const onSubmit = () => {
        setLoad(true);
        dispatch(setLoading(true));
        postFollowup({
            reason,
            ...{
                patientPhoneNumber: consult.patients[0].username,
                patientFullname: consult.patients[0].fullname,
                age: consult.patients[0].patient.age,
                gender: consult.patients[0].patient.gender,
                "hospitalPlan": consult.hospitalPlan,
                "accidentPlanLimit": consult.accidentPlanLimit,
                "exclusion": consult.exclusion,
            }
        }, consultId).then(value => {
            if (value.status === 201) {
                const resultID = value.data._id;
                history.push(`/agent/consult/chat/${resultID}`)
            }
        }).catch(reason1 => {
            alert(reason1.response?.data?.message || "Cannot follow up");
        }).finally(() => {
            dispatch(setLoading(false));
            setLoad(false);
        })
    }

    const getUserName = (qbUId: string) => {
        const arrayUsers = [...consult.doctors, ...consult.patients, ...consult.agents];
        const findItem = arrayUsers.find((value, index) => value._id.toString() === qbUId.toString());
        return findItem && findItem.fullname ? findItem.fullname : qbUId;
    }

    return consult ? (
        <Container>
            <Nav isNavAgent={true} menu={false} title="Followup consult"/>
            <ContainerItem>
                <Title>ConsultID: {consultId}</Title>
                <TextRow>
                    <Label>Patient Name</Label>
                    <TextBold>{getUserName(consult.patients[0]._id) || ''}</TextBold>
                </TextRow>
                <TextRow>
                    <Label>Gender</Label>
                    <Text>{consult.gender}</Text>
                </TextRow>
                <TextRow>
                    <Label>Age</Label>
                    <Text>{consult.age}</Text>
                </TextRow>
                <TextRow>
                    <Label>Hospital Plan</Label>
                    <Text>{consult.hospitalPlan}</Text>
                </TextRow>
                <TextRow>
                    <Label>Accident Plan Limit</Label>
                    <Text>{consult.accidentPlanLimit}</Text>
                </TextRow>
                <TextRow>
                    <Label>Exclusion</Label>
                    <Text>{consult.exclusion}</Text>
                </TextRow>
                <TextRow>
                    <Label>Reason of consult</Label>
                    <Text>{consult.reason}</Text>
                </TextRow>
            </ContainerItem>

            <TitleBlock>Reason of followup</TitleBlock>
            <ContainerItem>
                <TextRow>
                    <Label>Reason of followup</Label>
                    <Input defaultValue={reason} value={reason} onChange={event => setReason(event.target.value)}/>
                </TextRow>
            </ContainerItem>

            <PinButton>
                <PinButtonContent>
                    <Button disabled={load} onClick={event => onSubmit()} className={'center'}>Connect to DA Doctor</Button>
                </PinButtonContent>
            </PinButton>
        </Container>
    ) : null
}
