export enum MessageType {
    MakePhone = '4',
    LeaveGroup = '3',
    AddUser = '2',
    CreateGroup = '1',
    JoinGroup = '5',
    DoctorJoinGroup = '6',
    JOINCall = '7',
    ENDCall = '10',
    NewCall = '8',
}
