import React, {useEffect, useState} from "react";
import Nav from "../../components/Nav";
import styled from "styled-components";
import {Button, CenterStyled, Input} from "../../components/Elements";
import {
    getConsultById,
    getConsultCaseNote,
    getConsultCaseNotePastVisit,
    postFollowup
} from "../../services/app.service";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {ReactComponent as ArrowDownIcon} from '../../assets/images/down.svg';
import {ReactComponent as CalendarIcon} from '../../assets/images/calendar.svg';
import moment from "moment";
import {useDispatch} from "react-redux";
import {setLoading} from "../../redux/actions/app.action";

const ContainerItem = styled.div`
  padding: 9px 16px;
  width: 100%;
  background: rgba(252, 253, 253, 0.78);
  border-radius: 16px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px;
  position: relative;
`;

const TextBold = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3A3B56;
  margin: 6px 0px;
  display: inline-block;
`;
const Text = styled.span`
  font-size: 16px;
  line-height: 18px;
  color: #3A3B56;
  margin: 8px 0px;
  display: block;
  font-family: 'Bebeboo-Regular', sans-serif;
`;
const Label = styled.span`
  font-size: 16px;
  line-height: 18px;
  color: #2A2F36;
  margin: 6px 0px;
  font-family: 'Bebeboo-SemiBold', sans-serif;
  display: block;
`;
const Title = styled.span`
  font-size: 18px;
  line-height: 18px;
  color: #4DBAAB;;
  margin: 8px 0px;
  display: block;
  cursor: pointer;
`;

const TextRow = styled.div`
  margin: 15px 0;
`;

const TitleBlock = styled.span`
  font-size: 22px;
  line-height: 24px;
  color: #000;;
  margin: 4px 0px;
  display: block;
`;

const PinButtonContent = styled.div`max-width: 500px;
  display: flex;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  flex: 1;
  height: 70px;
  padding: 10px;
`;


const PinButton = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

`;

const Container = styled.div`
  position: relative;
  padding-bottom: 80px;
`;

const ArrowDown = styled(ArrowDownIcon)`
  position: absolute;
  right: 16px;
  top: 24px;
`

const ArrowUp = styled(ArrowDownIcon)`
  position: absolute;
  right: 16px;
  top: 24px;
  transform: rotate(180deg);
`

const BoxItem = styled.div`
  background: #FFFFFF;
  border: 1px solid #E3F3F9;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 16px;
`;
const BoxItemHead = styled.div`
  background: #F1F4F6;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
`;
const BoxItemBody = styled.div`
  padding: 0px 16px;
`;
const BoxItemHeadIcon = styled(CalendarIcon)`
  margin-right: 10px;
`;
const BoxItemHeadTitle = styled.div`
  font-size: 16px;
  color: #4DBAAB;
`;
const BoxItemHeadDesc = styled.div`
  font-size: 14px;
  color: #3A3B56;
  margin-top: 4px;
`;
const BoxItemHeadRight = styled.div``;
const BoxItemText = styled.div`
  font-size: 14px;
  color: #4DBAAB;
  font-family: 'Bebeboo-Regular', sans-serif;
`;

const BoxItemTitle = styled.div`
  font-size: 16px;
  color: #4DBAAB;
  padding: 12px 0;
  margin: 0 16px;
  border-bottom: 1px solid rgba(6, 82, 126, 0.2);
`;

const TextWarn = styled.div`
  font-size: 16px;
  color: #F55046;
`;

const BoxButton = styled.div`
  height: 40px;
  background: #F3F7FC;
  border-radius: 8px;
  line-height: 40px;
  padding: 0 16px;
  margin-bottom: 12px;
`;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function ArchiveDetailScreen(props: any) {
    const history = useHistory();
    let {consultId}: any = useParams();
    const [consult, setConsult]: any = useState();
    const [casenote, setCaseNote]: any = useState({});
    const [pastMedicalHistory, setPastMedicalHistory]: any = useState({});
    const [blockVisible, setBlockVisible] = useState([true]);
    const query = useQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        const run = async () => {
            dispatch(setLoading(true));

            await getConsultById(consultId).then(value => {
                console.log(value);
                setConsult(value.data);
            })
            await getConsultCaseNote(consultId).then(value => {
                setCaseNote(value.data.data && value.data.data.length > 0 ? value.data.data[0] : {});
                // setPastMedicalHistory(value.data.data || []);
            });
            await getConsultCaseNotePastVisit(consultId).then(value => {
                setPastMedicalHistory(value.data.data || []);
            });
            dispatch(setLoading(false));

        }
        run().then();
    }, []);

    const toggleView = (index: number) => {
        const cached = [...blockVisible];
        cached[index] = !cached[index];
        setBlockVisible(cached);
    }

    const openMap = () => {
        history.push('/agent/archive/map/' + consultId)
    }

    const openFollowup = () => {
        history.push('/agent/archive/followup/' + consultId)
    }

    const getUserName = (qbUId: string) => {
        const arrayUsers = [...consult.doctors, ...consult.patients, ...consult.agents];
        const findItem = arrayUsers.find((value, index) => value._id.toString() === qbUId.toString());
        return findItem && findItem.fullname ? findItem.fullname : qbUId;
    }

    return consult ? (
        <Container className='view-bg '>
            <Nav isNavAgent={true} menu={false} title={`ConsultID: ${consultId}`}/>
            <ContainerItem>
                <Title onClick={() => toggleView(0)}>Particulars</Title>

                {
                    blockVisible[0] ? (
                        <>
                            <TextRow>
                                <Label>Patient Name</Label>
                                <Text>{getUserName(consult.patients[0]._id) || ''}</Text>
                            </TextRow>
                            <TextRow>
                                <Label>Gender</Label>
                                <Text>{consult.gender}</Text>
                            </TextRow>
                            <TextRow>
                                <Label>Age</Label>
                                <Text>{consult.age}</Text>
                            </TextRow>
                            <TextRow>
                                <Label>Hospital Plan</Label>
                                <Text>{consult.hospitalPlan}</Text>
                            </TextRow>
                            <TextRow>
                                <Label>Accident Plan Limit</Label>
                                <Text>{consult.accidentPlanLimit}</Text>
                            </TextRow>
                            <TextRow>
                                <Label>Exclusion</Label>
                                <Text>{consult.exclusion}</Text>
                            </TextRow>
                            <TextRow>
                                <Label>Reason of consult</Label>
                                <Text>{consult.reason || "---"}</Text>
                            </TextRow>
                        </>
                    ) : null
                }
                {
                    !blockVisible[0] ? <ArrowDown onClick={() => toggleView(0)}/> :
                        <ArrowUp onClick={() => toggleView(0)}/>
                }
            </ContainerItem>

            {
                pastMedicalHistory.length > 0 ? (
                    <ContainerItem>
                        <Title onClick={() => toggleView(1)}>Past Medical History</Title>
                        {
                            blockVisible[1] ? (
                                <>
                                    <TextRow>
                                        <Label>Existing Drug Allergies</Label>
                                        <Text>
                                            {pastMedicalHistory.map((v: any, i: number) => {
                                                return v.drugAllergies.length > 0 ? v.drugAllergies.map((value: any, index: number) => value).join(', ') : null
                                            }).filter((x: any) => !!x).join(', ')}
                                        </Text>
                                    </TextRow>
                                    <TextRow>
                                        <Label>Past Visits</Label>
                                        {
                                            pastMedicalHistory.map((valueX: any, index: number) => {
                                                return (
                                                    <BoxItem>
                                                        <BoxItemHead>
                                                            <BoxItemHeadIcon/>
                                                            <BoxItemHeadRight>
                                                                <BoxItemHeadTitle>{moment(valueX.createdAt).format('DD/MM/YYYY')}</BoxItemHeadTitle>
                                                                <BoxItemHeadDesc>{moment(valueX.createdAt).format('hh:mm a')}</BoxItemHeadDesc>
                                                            </BoxItemHeadRight>
                                                        </BoxItemHead>
                                                        <BoxItemBody>
                                                            <TextRow>
                                                                <BoxItemText>Diagnosis</BoxItemText>
                                                                <Text>{valueX.diagnosis.map((value: any, index: number) => value).join(', ')}</Text>
                                                            </TextRow>
                                                            <TextRow>
                                                                <BoxItemText>Meds Given</BoxItemText>
                                                                <Text>{valueX.prescription.map((value: any, index: number) => value.name).join(', ') || '---'}</Text>
                                                            </TextRow>
                                                            <TextRow>
                                                                <BoxItemText>Disposition</BoxItemText>
                                                                {
                                                                    valueX.disposition.key ?
                                                                        <Text>{valueX.disposition.key}: {valueX.disposition.name}</Text> :
                                                                        <Text>---</Text>
                                                                }
                                                            </TextRow>
                                                        </BoxItemBody>
                                                    </BoxItem>
                                                )
                                            })
                                        }
                                    </TextRow>
                                </>
                            ) : null
                        }
                        {
                            !blockVisible[1] ? <ArrowDown onClick={() => toggleView(1)}/> :
                                <ArrowUp onClick={() => toggleView(1)}/>
                        }
                    </ContainerItem>
                ) : null
            }

            {
                casenote.symptoms ? (
                    <ContainerItem>
                        <Title onClick={() => toggleView(2)}>Symptoms</Title>

                        {
                            blockVisible[2] ? (
                                <>
                                    <TextRow>
                                        {
                                            casenote.symptoms.length > 0 ? casenote.symptoms.map((value: any, index: number) => {
                                                return (
                                                    <BoxItem>
                                                        <BoxItemTitle>{value.title}</BoxItemTitle>
                                                        <BoxItemBody>
                                                            <TextRow>
                                                                <Label>Day (s)</Label>
                                                                <Text>{value.days}</Text>
                                                            </TextRow>
                                                            <TextRow>
                                                                <Label>Note</Label>
                                                                <Text>{value.note}</Text>
                                                            </TextRow>
                                                        </BoxItemBody>
                                                    </BoxItem>
                                                )
                                            }) : <span className={'no-data'}>No Data</span>
                                        }
                                    </TextRow>

                                </>
                            ) : null
                        }
                        {
                            !blockVisible[2] ? <ArrowDown onClick={() => toggleView(2)}/> :
                                <ArrowUp onClick={() => toggleView(2)}/>
                        }
                    </ContainerItem>
                ) : null
            }

            {
                casenote.diagnosis ? (
                    <ContainerItem>
                        <Title onClick={() => toggleView(3)}>Diagnosis</Title>

                        {
                            blockVisible[3] ? (
                                <>
                                    <TextRow>
                                        {
                                            casenote.diagnosis.length > 0 ? casenote.diagnosis.map((value: any, index: number) => {
                                                return (
                                                    <BoxButton key={index}>{value}</BoxButton>
                                                )
                                            }) : <span className={'no-data'}>No Data</span>
                                        }
                                    </TextRow>

                                </>
                            ) : null
                        }
                        {
                            !blockVisible[3] ? <ArrowDown onClick={() => toggleView(3)}/> :
                                <ArrowUp onClick={() => toggleView(3)}/>
                        }
                    </ContainerItem>
                ) : null
            }

            {
                casenote.disposition ? (
                    <ContainerItem>
                        <Title onClick={() => toggleView(4)}>Disposition</Title>

                        {
                            blockVisible[4] ? (
                                <>
                                    <TextRow>
                                        <Label>Disposition</Label>
                                        {
                                            casenote.disposition.key ?
                                                <Text>{casenote.disposition.key}: {casenote.disposition.name}</Text> :
                                                <Text>---</Text>
                                        }
                                    </TextRow>
                                    <TextRow>
                                        <Label>Comment</Label>
                                        <Text>{casenote.disposition.comment}</Text>
                                    </TextRow>
                                </>
                            ) : null
                        }
                        {
                            !blockVisible[4] ? <ArrowDown onClick={() => toggleView(4)}/> :
                                <ArrowUp onClick={() => toggleView(4)}/>
                        }
                    </ContainerItem>
                ) : null
            }

            {
                casenote.prescription ? (
                    <ContainerItem>
                        <Title onClick={() => toggleView(5)}>Prescription</Title>

                        {
                            blockVisible[5] ? (
                                <>
                                    {!casenote.prescription || casenote.prescription.length === 0 ?
                                        <TextWarn>No Medicine</TextWarn> : null}
                                    <TextRow>
                                        {
                                            casenote.prescription.length >= 0 ? casenote.prescription.map((value: any, index: number) => {
                                                return (
                                                    <BoxItem key={index}>
                                                        <BoxItemTitle>{value.name}</BoxItemTitle>
                                                        <BoxItemBody>
                                                            <TextRow>
                                                                <Label>Dosage Qty</Label>
                                                                <Text>{value.dosageQty || '---'}</Text>
                                                            </TextRow>
                                                            <TextRow>
                                                                <Label>Frequency ( number of time a day)</Label>
                                                                <Text>{value.frequency || '---'}</Text>
                                                            </TextRow>
                                                            <TextRow>
                                                                <Label>Dispense Qty</Label>
                                                                <Text>{value.dispenseQty || '---'}</Text>
                                                            </TextRow>
                                                            <TextRow>
                                                                <Label>Tablet</Label>
                                                                <Text>{value.dispenseUOM || '---'}</Text>
                                                            </TextRow>
                                                            <TextRow>
                                                                <Label>Note</Label>
                                                                <Text>{value.comment || '---'}</Text>
                                                            </TextRow>
                                                        </BoxItemBody>
                                                    </BoxItem>
                                                )
                                            }) : <span className={'no-data'}>No Data</span>
                                        }
                                    </TextRow>

                                </>
                            ) : null
                        }
                        {
                            !blockVisible[5] ? <ArrowDown onClick={() => toggleView(5)}/> :
                                <ArrowUp onClick={() => toggleView(5)}/>
                        }
                    </ContainerItem>
                ) : null
            }

            {
                casenote.questions ? (
                    <ContainerItem>
                        <Title style={{cursor: "pointer"}} onClick={() => toggleView(6)}>Questionnaire</Title>

                        {
                            blockVisible[6] ? (
                                <>
                                    <TextRow>
                                        <BoxItem>
                                            {
                                                casenote.questions.length > 0 ? casenote.questions.map((value: any, indexQ: number) => {
                                                    return (
                                                        <BoxItemBody>
                                                            <TextRow>
                                                                <Label>{value.question}</Label>
                                                                <Text>
                                                                    {value.answers.find((x: any) => x.selected) ? (value.answers.find((x: any) => x.selected).valueTf || value.answers.find((x: any) => x.selected).value) : ''}
                                                                </Text>
                                                            </TextRow>
                                                            {indexQ < casenote.questions.length - 1 ? <div
                                                                style={{borderBottom: '1px solid #efefef'}}/> : null}
                                                        </BoxItemBody>
                                                    )
                                                }) : <span className={'no-data'}>No Data</span>
                                            }
                                        </BoxItem>
                                    </TextRow>

                                </>
                            ) : null
                        }
                        {
                            !blockVisible[6] ? <ArrowDown onClick={() => toggleView(6)}/> :
                                <ArrowUp onClick={() => toggleView(6)}/>
                        }
                    </ContainerItem>
                ) : null
            }

            {consult && !query.get('isFollowup') ? (
                <PinButton>
                    <PinButtonContent>
                        <Button onClick={() => openMap()} style={{width: '48%'}} className={'center'}>View Consult
                            Map</Button>
                        <Button onClick={() => openFollowup()} style={{width: '48%'}} className={'center'}>Create Follow
                            up</Button>
                    </PinButtonContent>
                </PinButton>
            ) : null}
        </Container>
    ) : null;
}
