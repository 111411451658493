import React, {useEffect, useRef, useState} from 'react';
import './style.scss';
import './style.scss';
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
// @ts-ignore
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    MessageSeparator,
    // @ts-ignore
} from '@chatscope/chat-ui-kit-react';
import Nav from "../../components/Nav";
import styled, {ThemeProvider} from "styled-components";
import {ReactComponent as DeclineIcon} from "../../assets/images/decline.svg";
import {ReactComponent as SendIcon} from "../../assets/images/send.svg";
import {Button, Input} from "../../components/Elements";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import QuickbloxService from "../../services/quickblox.service";
import {
    getConsultById,
    getConsultsByPatient,
    getPatientConsultId,
    getSessionUserId, leaveCall,
    setPatientConsultId
} from "../../services/app.service";
import CallScreen from "../patient/call.screen";
import CallingScreen from "../patient/calling.screen";
import moment from "moment";
import {MessageType} from "../../constants/message.type";
import {setLoading} from "../../redux/actions/app.action";
import {MeetingProvider, lightTheme, RosterAttendee} from 'amazon-chime-sdk-component-library-react';
import ChimeMessagingService from '../../services/chime.messaging.service';
import {sendChannelMessage, listChannelMessages} from '../../services/chime.service';

const CallInfo = styled.div``;
const CallActions = styled.div`
  font-family: 'Bebeboo-Regular', sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 240px;
  margin-top: 30px;
`;
const Container = styled.div`
  background: #fff;
  backdrop-filter: blur(100px);
  border-radius: 0px;
  min-height: calc(100% - 120px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 9;
  padding-top: 30px;
`;
const Image = styled.img`
  border: 9px solid #E2FBFF;
  box-sizing: border-box;
  border-radius: 90px;
  width: 180px;
  height: 180px;
  padding: 14px;
  margin-bottom: 18px;
`;
const Name = styled.div`
  font-size: 20px;
  line-height: 23px;
  color: #009599;
  text-align: center;
  width: 100%;
  margin-bottom: 6px;
`;
const Status = styled.div`
  font-size: 18px;
  line-height: 21px;
  color: #3A3B56;
  text-align: center;
  width: 100%;
`;

const HEIGHT = (window.innerHeight - 150);

const InCall = (props: any) => {
    const nav = useHistory();

    const onCall = () => {
        nav.push('/call');
    }

    return <Container>
        <CallInfo>
            <Image src='https://image.freepik.com/free-vector/doctor-character-background_1270-84.jpg'/>
            <Name>Dr. James</Name>
            <Status>has initiated video call</Status>
        </CallInfo>
        <CallActions>
            <Button onClick={() => onCall()}>Join Call</Button>
        </CallActions>
    </Container>
}

const reload = () => {
    window.location.reload();
}

export default function ConsultChatScreen(props: any) {
    const {type}: any = useParams();
    const [hasCall, setHasCall] = useState(false);
    const [messages, setMessages]: any = useState([]);
    const isNavAgent = (type === 'agent');
    const [consult, setConsult]: any = useState(null);
    const user = useSelector((state: any) => state.Auth.user);
    let userQBId = getSessionUserId();
    const [message, setMessage]: any = useState(null);
    const [loading, setLocalLoading]: any = useState(true);
    const [calling, setCalling]: any = useState(false);
    const [incomingCall, setIncomingCall]: any = useState(false);
    const [session, setSession]: any = useState();
    const [sessionType, setSessionType]: any = useState();
    const [sessionCallType, setSessionCallType]: any = useState('AUDIO');
    const [callUsers, setCallUsers]: any = useState([]);
    const [remoteStreams, setRemoteStreams]: any = useState(null);
    const [copied, setCopied]: any = useState(false);
    const [popupNewMessage, setPopupNewMessage]: any = useState(false);
    const [textMessage, setTextMessage]: any = useState('');
    const [channelArn, setChannelArn]: any = useState('');
    let {consultId}: any = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const isPatient = process.env.REACT_APP_DEFAULT_TYPE === 'patient';
    const msgListRef: any = useRef();
    const [messagingService] = useState(() => new ChimeMessagingService());

    useEffect(() => {
        if (user && consult) {
            setChannelArn(consult.channelArn);
            // Start messaging service
            dispatch(setLoading(true));
            messagingService.connect(user.appInstanceUserArn, user.chatToken).then(async (value: any) => {
                messagingService.subscribeToMessageUpdate((mess: any) => {
                    if (mess.type === "CREATE_CHANNEL_MESSAGE") {
                        onNewMessage(JSON.parse(mess.payload))
                    }
                });
                // List all message
                const messes = await listChannelMessages({
                    memberArn: user.appInstanceUserArn,
                    chatToken: user.chatToken,
                    channelArn: consult.channelArn
                });
                setMessages(messes.Messages.map(x => mapMessages(x)));
            }).finally(() => {
                dispatch(setLoading(false));
            });

            return () => {
                messagingService.close();
            };
        }
    }, [user, consult]);

    useEffect(() => {
        if (isPatient && !getPatientConsultId() && !user) {
            history.push(`/patient/auth/login/${consultId}`);
        }

        const run = async () => {
            // dispatch(setLoading(true));
            userQBId = getSessionUserId();
            const result: any = await getConsultById(consultId).catch(reason => null);
            if (result && result.status === 200) {
                const ctx = result.data;
                setConsult(ctx);
                if (ctx.chimeInfo && ctx.chimeInfo.call_status === 'START') {
                    setIncomingCall(true);
                }

                // setLocalLoading(false);
                // dispatch(setLoading(false));
                // }
                // await QuickbloxService.listDialogMessage(ctx.qbDialogId).then((value: any) => {
                //     let messagesCache = value.items.map((x: any) => mapMessages(x)).reverse();
                //     messagesCache = messagesCache.map((msg: any) => {
                //         let doctorId = null;
                //         try {
                //             const doctorSender = JSON.parse(msg.new_occupants_ids);
                //             if (doctorSender && doctorSender.length > 0) {
                //                 doctorId = doctorSender[0];
                //             }
                //         } catch (e) {
                //         }
                //         if (doctorId) {
                //             msg.sender = doctorId;
                //             msg.sender_id = doctorId;
                //         }
                //         return msg;
                //     })
                //
                //     setMessages(messagesCache);
                //     setTimeout(() => {
                //         const findJoin = messagesCache.find((x: any) => (x || {}).notification_type === MessageType.JoinGroup && (x || {}).sender.toString() === userQBId.toString())
                //         if (!findJoin) {
                //             onSentMessage(`${user.fullname} has joined the group`, MessageType.JoinGroup, ctx);
                //         }
                //     }, 1000)
                // });
                // Listen new message
                // QuickbloxService.subscribeNewMessage(ctx.qbDialogId, (evt: any) => {
                //     console.log('BBB', evt);
                //     setMessage(evt);
                // });
            } else {
                setPatientConsultId(consultId);
                history.push(`/${type}/auth/login`);
            }

        }
        run().then();

    }, [consultId]);

    // Sent join message
    useEffect(() => {
        if (consult && !loading) {

        }
        return () => {
            if (consult && !loading) {
                // onSentMessage(`${user.fullname} has left the group`, MessageType.LeaveGroup);
            }
        }
    }, [loading])

    useEffect(() => {
        if (message) {
            onNewMessage(JSON.parse(JSON.stringify(message)));
        }
    }, [message]);

    useEffect(() => {
        // QuickbloxService.onRemoteStreamListener((session: any, userId: any, stream: any) => {
        //     setRemoteStreams({session, userId, stream})
        // });
        // QuickbloxService.onSessionConnectionStateChangedListener((session: any, userId: any, stateId: any) => {
        //     setRemoteStreams({session, userId, stateId})
        // });
        // QuickbloxService.onStopCallListener((session: any, userId: any, stream: any) => {
        //     setCalling(false);
        //     reload();
        // });
        // QuickbloxService.onCallListener((session: any, ex: any) => {
        //     setIncomingCall(true);
        //     setSession(session);
        // });
    }, []);


    const loadConsult = async () => {
        const result: any = await getConsultById(consultId).catch(reason => null);
        if (result && result.status === 200) {
            const ctx = result.data;
            setConsult(ctx);

        }
    }
    const getUserName = (message: any) => {
        return message?.sender || message?.Sender?.Name || '';
    }


    const mapMessages = (x: any): any => {

        const date = (typeof x.CreatedTimestamp) === 'string' ? x.CreatedTimestamp : moment(x.CreatedTimestamp).toISOString();
        let meta: any = {};
        if (x.Metadata) {
            meta = JSON.parse(x.Metadata);
            if (meta) {
                meta.sender = meta.name || '';
                meta.sender = meta.type === 'DOCTOR' ? `Dr. ${meta.sender}` : meta.sender;
            }
        }
        console.log({
            ...x.extension,
            ...x,
            message: x.Content,
            sentTime: moment(date).toDate(),
            sender: x.Sender.Name,
            doctorId: (x.doctorId || ''),
            direction: !isMe(x) ? "incoming" : "outgoing",
            position: "single",
            ...meta
        });
        return {
            ...x.extension,
            ...x,
            message: x.Content,
            sentTime: moment(date).toDate(),
            sender: x.Sender.Name,
            doctorId: (x.doctorId || ''),
            direction: !isMe(x) ? "incoming" : "outgoing",
            position: "single",
            ...meta
        }
    }

    const onNewMessage = (newMessage: any) => {
        newMessage = mapMessages(newMessage)
        if (newMessage.notification_type === MessageType.JoinGroup) {
            loadConsult().then();
        }
        if (newMessage.notification_type === MessageType.DoctorJoinGroup) {
            loadConsult().then();
        }
        if (newMessage.notification_type === MessageType.NewCall) {
            setIncomingCall(true);
        }
        if (newMessage.notification_type === MessageType.ENDCall) {
            setCalling(false);
            reload();
        }

        setMessages((prevState: any) => {
            const ms = [...prevState];
            ms.push(newMessage);
            return ms;
        });
        setPopupNewMessage(true);
    }

    const onSentMessage = (content: any, type: string = 'message', consultCache: any = null) => {
        sendChannelMessage(content, {
            memberArn: user.appInstanceUserArn,
            chatToken: user.chatToken,
            channelArn: consult.channelArn
        }).then()
    }

    const onCall = async (evtName: string) => {
        userQBId = getSessionUserId();
        switch (evtName) {
            case "SIGN_OUT": {
                history.push(`/${type}/logout`);
                break;
            }
            case "VIDEO_CALL": {
                const users: any = await QuickbloxService.getListUsers(consult.qbDialogId);
                const otherUsers = users.filter((u: any) => u.toString() !== userQBId.toString());
                setCallUsers(otherUsers);
                const session = QuickbloxService.createCallSession(otherUsers, "VIDEO");
                setSession(session);
                setSessionType('OUT');
                setSessionCallType('VIDEO');
                setCalling(true);
                break;
            }
            case "AUDIO_CALL": {
                const users: any = await QuickbloxService.getListUsers(consult.qbDialogId);
                const otherUsers = users.filter((u: any) => u.toString() !== userQBId.toString());
                setCallUsers(otherUsers);
                const session = QuickbloxService.createCallSession(otherUsers, "AUDIO");
                setSession(session);
                setSessionType('OUT');
                setSessionCallType('AUDIO');
                setCalling(true);
                break;
            }
            case "SHARE": {
                const shareData = {
                    title: 'Doctor Anywhere',
                    text: '',
                    url: `${process.env.REACT_APP_SHARE_PATIENT_HOST || 'https://dev.patient.da-care.co'}/patient/auth/login/${consultId}`,
                }
                try {
                    window.navigator.clipboard.writeText(shareData.url);
                } catch (e) {
                }
                try {
                    window.navigator.share(shareData);
                } catch (err) {
                    //alert('Copied link!');
                    setCopied(true);
                    setTimeout(() => {
                        setCopied(false);
                    }, 2000)
                }

                break;
            }
        }
    }

    const onCallAccept = () => {
        setSessionType('IN');
        // setCalling(true);
        setIncomingCall(false);
        history.push(`/agent/consult/call/${consultId}`)
    }

    const onCallDecline = () => {
        session.reject({});
        // setSessionType('IN');
        // setCalling(true);
        setIncomingCall(false);
    }

    const handleScroll = () => {
        msgListRef.current.scrollToBottom('smooth')
    };

    let currentDay = '';
    const renderDay = (value: any, index: any) => {
        let nextDay = moment(value && value.sentTime ? value.sentTime * (value.sentTime < 9623211977 ? 1000 : 1) : new Date()).format('DD/MM/YYYY');
        if (currentDay !== nextDay) {
            currentDay = nextDay;
            return (
                <MessageSeparator key={index + 'time'}>
                    {moment(value && value.sentTime ? value.sentTime * (value.sentTime < 9623211977 ? 1000 : 1) : new Date()).format('DD/MM/YYYY HH:mm')}
                </MessageSeparator>
            )
        }
        return null;
    }

    const isMe = (value: any) => {
        return value?.Sender.Arn.toString() === user.appInstanceUserArn.toString();
    }

    const renderMessage = (value: any, index: number) => {
        switch (value.notification_type ? value.notification_type.toString() : '') {
            case MessageType.JoinGroup: {
                return (<MessageSeparator
                    key={index.toString()}>{isMe(value) ? 'You have joined the group' : `${getUserName(value)} has joined the group`} </MessageSeparator>)
            }
            case MessageType.DoctorJoinGroup: {
                return (<MessageSeparator
                    key={index.toString()}>{isMe(value) ? 'You have joined the group' : `${getUserName(value)} has joined the group`} </MessageSeparator>)
            }
            case MessageType.LeaveGroup: {
                return (<MessageSeparator
                    key={index.toString()}>{isMe(value) ? 'You have left the group' : `${getUserName(value)} has left the group`} </MessageSeparator>)
            }
            case MessageType.MakePhone: {
                return (<MessageSeparator
                    key={index.toString()}>{isMe(value) ? 'You initiated a video call' : `${getUserName(value)} started the call`} </MessageSeparator>)
            }
            case MessageType.CreateGroup: {
                return (<MessageSeparator
                    key={index.toString()}>{isMe(value) ? 'You have created a new consult request' : `${getUserName(value)} has created a new consult request`} </MessageSeparator>)
            }
            case MessageType.AddUser: {
                return (<MessageSeparator
                    key={index.toString()}>{isMe(value) ? 'You added user the group' : `${getUserName(value)} added user the group`} </MessageSeparator>)
            }
            case MessageType.JOINCall: {
                return (<MessageSeparator
                    key={index.toString()}>{isMe(value) ? 'You have joined the call' : `${getUserName(value)} has joined the call`} </MessageSeparator>)
            }
            case MessageType.ENDCall: {
                return (<MessageSeparator
                    key={index.toString()}>{isMe(value) ? 'You have end the call' : `${getUserName(value)} ended the call`} </MessageSeparator>)
            }
            case MessageType.NewCall: {
                return (<MessageSeparator
                    key={index.toString()}>{isMe(value) ? 'You have created the call' : `${getUserName(value)} started the call`} </MessageSeparator>)
            }
            default: {
                return (
                    <Message key={index.toString()}
                             model={{...value, type: 'html'}}>
                        <Message.HtmlContent key={index.toString()}
                                             html={`<strong class="name-chat">${getUserName(value)}</strong><br/>${value.message}`}/>
                        <Message.Footer
                            sentTime={moment(value && value.sentTime ? value.sentTime * (value.sentTime < 9623211977 ? 1000 : 1) : new Date()).format('HH:mm')}/>
                    </Message>
                )
            }
        }
    }
    return (
        <div className="chat-consult">
            {
                copied ? <span className={'link-copy'}>Link copied!</span> : null
            }
            {
                popupNewMessage ? <span onClick={event => {
                    setTimeout(() => {
                        handleScroll();
                    }, 100);
                    setPopupNewMessage(false);
                }} className={'new-message'}>New message</span> : null
            }
            <Nav backTo={`/${type}/home`} onEvent={(evt: string) => onCall(evt)} isNavAgent={isNavAgent}
                 menu={true}
                 title={`ConsultID: ${consultId}`}/>
            <div className="chat-container">
                {hasCall ? <InCall/> : null}
                <MainContainer>
                    <ChatContainer>
                        <MessageList onYReachEnd={(x: any) => {
                            setPopupNewMessage(false);
                        }} style={{height: incomingCall ? (HEIGHT - 50) : HEIGHT}} ref={msgListRef}>
                            {!loading && messages[0] && messages[0].sentTime ? (
                                <MessageSeparator>Text Message</MessageSeparator>
                            ) : null}
                            {
                                messages ? messages.map((value: any, index: any) => {
                                    return <>
                                        {renderDay(value, index) || null}
                                        {renderMessage(value, index) || null}
                                    </>
                                }) : null
                            }
                        </MessageList>
                    </ChatContainer>
                    <div className={'text-input'}>
                        <input
                            type={'text'}
                            onKeyPress={(e: any) => {
                                if (e.charCode === 13) {
                                    onSentMessage(textMessage.trim());
                                    setTextMessage('');
                                    // @ts-ignore
                                    document.querySelector('.text-input input')?.focus();
                                }
                            }}
                            autoFocus={false} placeholder="Type message here"
                            value={textMessage} onChange={event => {
                            setTextMessage(event.target.value);
                        }}/>
                        <SendIcon onClick={() => {
                            onSentMessage(textMessage.trim());
                            setTextMessage('');
                            // @ts-ignore
                            document.querySelector('.text-input input')?.focus();
                        }}/>
                    </div>
                </MainContainer>
                {
                    incomingCall ? (
                        <div className="popup-call-2">
                            <CallingScreen consult={consult} callId={session?.initiatorID}
                                           onAccept={() => onCallAccept()} onDecline={() => onCallDecline()}
                                           users={callUsers}/>
                        </div>
                    ) : null
                }
            </div>
        </div>
    );
}
