import React, {useEffect, useState} from 'react';
import {ReactComponent as Logo} from '../../assets/images/logo.svg';
import {ReactComponent as TopBackground} from '../../assets/images/topBg.svg';
import {Button, ControlInputStyled, Link, Title, Text, Label} from "../../components/Elements";
import PhoneControlStyled from "../../components/PhoneInput";
import {useHistory, useParams, useRouteMatch} from "react-router-dom";
import styled from "styled-components";
import {checkPhone, getPatientConsultId, setPatientConsultId} from "../../services/app.service";
import {useDispatch} from "react-redux";
import {setLoading} from "../../redux/actions/app.action";

const Top = styled.div`
  position: relative;
  width: 100%;
`;
const Center = styled.div`
  width: 100%;
  flex: none;
`;
const Bottom = styled.div`
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
`;
const ContainerApp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
export default function LoginScreen(props: any) {
    const history = useHistory();
    const [mobile, setMobile] = useState('');
    const {type, consultId}: any = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        setPatientConsultId(consultId);
    }, [])

    const onSubmit = () => {
        if (!mobile || mobile.length < 5) {
            alert('Mobile is a required field');
            return;
        }
        dispatch(setLoading(true));
        checkPhone(mobile, consultId).then(value => {
            history.push(`/${type}/auth/otp/${mobile}`);
        }).catch(reason => {
            alert(reason.response.data.message)
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }

    const onRegister = () => {
        history.push(`/${type}/auth/register`)
    }

    return (
        <ContainerApp className="auth-register">
            <Top>
                <div className="bg-top">
                    <TopBackground/>
                </div>
                <div className='logo'>
                    <Logo/>
                </div>
                <Title>{type === 'agent' ? 'Agent' : 'Patient'} Login</Title>
            </Top>
            <Center>
                <div className="controls">
                    <PhoneControlStyled onKeyPress={(e: any) => {
                        if (e.charCode === 13) {
                            onSubmit();
                        }
                    }} onChange={(phone: string) => setMobile(phone)}/>
                </div>
                <Text className="center" style={{padding: '0 10%'}}>
                    Enter your mobile number we will send
                    you OTP to verify
                </Text>
            </Center>
            <Bottom>

                <Button onClick={() => onSubmit()} className="button-register">Login</Button>
            </Bottom>
        </ContainerApp>
    );
}

